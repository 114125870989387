<script lang="ts">
import {DateTime, type DateTimeFormatOptions, Settings} from 'luxon';
import {computed, defineComponent, h, type PropType, Text} from 'vue';

const customFormats = {
  'website-medium': 'dd.LL.yyyy',
  'website-short': 'dd.LL',
};

export default defineComponent({
  props: {
    format: {
      default: DateTime.DATE_SHORT,
      type: [Object, String] as PropType<DateTimeFormatOptions | string>,
    },
    relative: {
      type: Boolean,
    },
    value: {
      required: true,
      type: [String, Object] as PropType<Date | DateTime | string | undefined |  null>,
    },
  },

  setup(props) {
    Settings.defaultLocale = 'nl';

    const date = computed<DateTime>(() => {
      if (DateTime.isDateTime(props.value)) {
        return props.value;
      } else if (props.value instanceof Date) {
        return DateTime.fromJSDate(props.value);
      } else {
        return DateTime.fromISO(
          props.value
            // Reformat plain datetime as ISO format
            ?.replace(/[\s](\d\d:)/g, 'T$1') ?? '',
        );
      }
    });

    const format = computed<DateTimeFormatOptions | string>(() => {
      const format = props.format;
      if (typeof format === 'string') {
        if (format in DateTime) {
          return DateTime[format];
        }

        if (format in customFormats) {
          return customFormats[format];
        }
      }
      return format;
    });

    return () => {
      if (!date.value) {
        return h(Text, '');
      }

      if (props.relative) {
        return h(Text, date.value.toRelative());
      }

      const formattedDate = typeof format.value === 'string'
        ? date.value.toFormat(format.value)
        : date.value.toLocaleString(format.value);

      return h('time', {datetime: date.value.toISO()}, formattedDate);
    };
  },
});
</script>
